import { useSelector, useDispatch } from 'react-redux';
import { selectErrors } from '../sagas/selectors';
import { errorsActions } from '../sagas/base/errors';

const useErrorLoader = (actionType) => {
    const errors = useSelector(selectErrors);
    const dispatch = useDispatch();

    // Check that we have an actionType
    if (actionType in errors) {
        return [
            actionType in errors,
            errors[actionType],
            () => dispatch(errorsActions.remove(actionType)),
            () => dispatch(errorsActions.reset())
        ];
    }

    // Return empty
    return [null, null, () => { }, () => { }];
};

export default useErrorLoader;
