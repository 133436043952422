import actionCreator from "utils/actionCreator";

/**
 * Action Types
 */
export const bookingsActionTypes = {
    READ: "@app/bookings/READ",
    DELETE: "@app/bookings/DELETE",
    UPDATE: "@app/bookings/UPDATE",
    APPOINTMENTS: "@app/bookings/APPOINTMENTS",
    CREATE: "@app/bookings/CREATE",
};

/**
 * Redux Actions
 */
export const bookingsActions = {
    read: actionCreator(bookingsActionTypes.READ),
    delete: actionCreator(bookingsActionTypes.DELETE),
    update: actionCreator(bookingsActionTypes.UPDATE),
    appointments: actionCreator(bookingsActionTypes.APPOINTMENTS),
    create: actionCreator(bookingsActionTypes.CREATE),
};

/**
 * Init State
 */
export const initState = {
    bookings: [],
    availableAppointments: [],
    total: 0,
};

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
const bookingsReducer = (state = initState, action) => {
    switch (action.type) {
        case bookingsActionTypes.READ:
            return { ...state, ...action.payload };

        case bookingsActionTypes.DELETE:
            return {
                ...state,
                bookings: [...state.bookings.filter(b => b.id !== parseInt(action.payload))],
                total: state.total - 1,
            };

        case bookingsActionTypes.APPOINTMENTS:
            return { ...state, availableAppointments: action.payload.availableAppointments };

        case bookingsActionTypes.CREATE:
            return { ...state, bookings: [action.payload, ...state.bookings] };

        case bookingsActionTypes.UPDATE:
            const bookings = state.bookings.map(booking => {
                if (booking.id === action.payload.id) return action.payload;
                return booking;
            });
            return { ...state, bookings };

        default:
            return state;
    }
};
export default bookingsReducer;
