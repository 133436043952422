import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { productsUpdateActions, productsUpdateActionTypes } from 'sagas/products/productsUpdate';
import useLoaderSelector from 'hooks/useLoaderSelector';
import useErrorSelector from 'hooks/useErrorSelector';

import Button from 'components/atoms/button/Button';
import Error from 'components/atoms/error/Error';
import Sidebar from 'components/templates/sidebar/Sidebar';
import FormGroup from "components/molecules/formGroup/FormGroup";

import dispatchWithPromise from 'utils/dispatchWithPromise';

const ProductSidebar = ({ onClose, product }) => {
    const dispatch = useDispatch();
    const [isLoading] = useLoaderSelector(productsUpdateActionTypes.REQUEST);
    const [isError] = useErrorSelector(productsUpdateActionTypes.FAILURE);

    const { register, handleSubmit, errors } = useForm({
        mode: 'onTouched',
        defaultValues: {
            title: product?.title || '',
            brand: product?.brand || '',
            description: product?.description || '',
            price: product?.price || 0,
            stock: product?.stock || 0,
            pinned: product?.pinned || false
        }
    });

    const onSubmit = (values) => {
        dispatchWithPromise(
            dispatch,
            productsUpdateActions.request,
            {
                ...values,
                productId: product.id,
            }
        ).then(() => onClose())
    }

    return (
        <Sidebar title="Detajet e produktit" onClose={onClose}>
            <div className="product-sidebar">
                <div className="product-sidebar__group">
                    <h5>Fotoja e produktit</h5>
                    <div>
                        <figure className="product-sidebar__figure">
                            <img src={product.imgUrl} alt="Product" />
                        </figure>
                    </div>
                </div>

                <div className="product-sidebar__group">
                    <h5>Info e produktit</h5>
                    <form className="product-sidebar__form">
                        <FormGroup ref={register({ required: 'Titulli eshte i nevojshem' })} name="title" type="text" label="Titulli" errors={errors} />

                        <FormGroup ref={register({ required: 'Brendi eshte i nevojshem' })} name="brand" type="text" label="Brendi" errors={errors} />

                        <FormGroup ref={register({ required: 'Pershkrimi eshte i nevojshem' })} name="description" type="textarea" label="Pershkrimi" errors={errors} />

                        <FormGroup ref={register({ required: 'Qmimi eshte i nevojshem' })} name="price" type="number" label="Qmimi" errors={errors} />

                        <FormGroup ref={register({ required: 'Sasia eshte e nevojshme' })} name="stock" type="number" label="Sasia" errors={errors} />

                        <FormGroup ref={register} name="pinned" type="checkbox" label="E Fiksuar" errors={errors} />

                        {isError && <Error size="medium" message="Produkti nuk ka mundur te ndryshohet" />}
                        <Button btnType="submit" onClick={handleSubmit(onSubmit)}>{!isLoading ? 'Submit' : 'Loading...'}</Button>
                    </form>
                </div>
            </div>
        </Sidebar>
    );
};


export default ProductSidebar;