import React from 'react';
import PropTypes from 'prop-types';

const TableInput = ({ defaultValue, onChange }) => {
    return (
        <div>
            <input
                className="table-input"
                type="number"
                min={0}
                defaultValue={defaultValue}
                onFocus={(e) => { if (e.target.value === '0') e.target.value = ''; }}
                onChange={onChange}
            />$
        </div>
    )
};

TableInput.propTypes = {
    defaultValue: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
}

export default TableInput;