import moment from 'moment';

const validateDate = (date) => {
    const currentDate = moment().toDate();
    const startDate = moment(date).toDate();
    const endDate = moment(startDate).toDate();
    const isSaturday = startDate.getDay() === 6;

    // Set hours to Trend working hours
    startDate.setHours(10);
    endDate.setHours(!isSaturday ? 20 : 18);

    if (currentDate >= endDate || startDate.getDay() === 0) { // Can only make appointments in the future and workdays + saturday
        return 'Data eshte e pasakte';
    }
    return true;
};

export default validateDate;