import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals';
import App from 'App';
import { Provider } from 'react-redux';

import configureStore from 'store/configureStore';
import { setupAxios } from 'utils/axios';

setupAxios();

// Setup store
const initialState = {};
const store = configureStore(initialState)

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
            <App />
        </Suspense>
    </Provider>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
