import React from 'react';

import ErrorIcon from 'static/svg/ErrorIcon';

const Error = ({ message, size = "small" }) => {
    return <p className={`error error--${size}`}>
        <ErrorIcon />
        {message}
    </p>
};

export default Error;