import { takeLatest, put } from "redux-saga/effects";
import decode from "jwt-decode";

import axios from "utils/axios";
import actionCreator from "utils/actionCreator";

/**
 * Action Types
 */
export const authActionTypes = {
    REQUEST: "@app/auth/REQUEST",
    LOGOUT: "@app/auth/LOGOUT",
    REGISTER: "@app/auth/REGISTER",
    FAILURE: "@app/auth/FAILURE",
    SUCCESS: "@app/auth/SUCCESS",
};

/**
 * Redux Actions
 */
export const authActions = {
    request: actionCreator(authActionTypes.REQUEST),
    logout: actionCreator(authActionTypes.LOGOUT),
    register: actionCreator(authActionTypes.REGISTER),
    success: actionCreator(authActionTypes.SUCCESS),
    failure: actionCreator(authActionTypes.FAILURE),
};

/**
 * Init State
 */
export const initState = {
    isAuthenticated: false,
    user: null,
};

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
const authReducer = (state = initState, action) => {
    switch (action.type) {
        case authActionTypes.REQUEST:
            return { ...state };

        case authActionTypes.SUCCESS:
            return { ...state, isAuthenticated: true, user: action.payload.user };

        case authActionTypes.LOGOUT:
            return initState;

        default:
            return state;
    }
};
export default authReducer;

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }) {
        try {
            const res = yield axios.post("/auth/admin", payload);
            yield put(authActions.register({ token: res.data.token }));
            resolve(res.data);
        } catch (err) {
            yield put(authActions.failure(err.response.data.errors));
            reject(err);
        }
    },
    *register({ payload }) {
        const decoded = decode(payload.token);
        if (decoded) {
            delete decoded.iat;
            yield put(authActions.success({ user: decoded }));
            yield localStorage.setItem("adm:token:v2", payload.token);
        }
    },
};

/**
 * Saga Watchers
 */
export const authWatchers = function* () {
    yield takeLatest(authActionTypes.REQUEST, sagas.request);
    yield takeLatest(authActionTypes.REGISTER, sagas.register);
};
