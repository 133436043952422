import "styles/main.scss";
import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import ProtectedRoute from 'utils/protectedRoute';

import history from "utils/history";
import Bookings from "pages/bookings/Bookings";
import Orders from "pages/orders/Orders";
import Barbers from "pages/barbers/Barbers";
import Products from "pages/products/Products";
import Login from 'pages/login/Login';
import MainLayout from "components/templates/mainLayout/MainLayout";

const App = () => {
    return (
        <Router history={history}>
            <MainLayout>
                <Switch>
                    <ProtectedRoute exact path="/products" component={Products} />
                    <ProtectedRoute exact path="/barbers" component={Barbers} />
                    <ProtectedRoute exact path="/orders" component={Orders} />
                    <Route exact path="/login" component={Login} />
                    <ProtectedRoute exact path="/" component={Bookings} />
                </Switch>
            </MainLayout>
        </Router>
    );
};

export default App;
