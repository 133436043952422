import React from 'react';

import useLoaderSelector from 'hooks/useLoaderSelector';
import { barbersReadActionTypes } from 'sagas/barbers/barbersRead';

import Nav from 'components/organisms/nav/Nav';

const MainLayout = (props) => {
    const [isLoading] = useLoaderSelector(barbersReadActionTypes.REQUEST);

    return (
        <div className="main-layout">
            <Nav />
            <div className="main-layout__content">
                {isLoading ? 'Loading...' : props.children}
            </div>
        </div>
    )
};


export default MainLayout;