import React from 'react';

const Heading = ({ type, children }) => {
    switch (type) {
        case 'primary':
            return <h1 className={`heading heading--${type}`}>{children}</h1>

        case 'secondary':
            return <h2 className={`heading heading--${type}`}>{children}</h2>

        default:
            return <h1 className={`heading heading--${type}`}>{children}</h1>
    }
};

export default Heading;