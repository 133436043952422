import { takeLatest, put, delay } from "redux-saga/effects";
import actionCreator from "utils/actionCreator";
import axios from "utils/axios";
import { bookingsActions } from "./bookings";

/**
 * Action Types
 */
export const bookingsCreateActionTypes = {
    REQUEST: "@app/bookings/create/REQUEST",
    FAILURE: "@app/bookings/create/FAILURE",
    SUCCESS: "@app/bookings/create/SUCCESS",
};

/**
 * Redux Actions
 */
export const bookingsCreateActions = {
    request: actionCreator(bookingsCreateActionTypes.REQUEST),
    success: actionCreator(bookingsCreateActionTypes.SUCCESS),
    failure: actionCreator(bookingsCreateActionTypes.FAILURE),
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }) {
        yield delay(500);
        try {
            const res = yield axios.post(`/bookings/block`, {
                barberId: parseInt(payload.barberId),
                date: payload.date,
                time: payload.time,
                amount: parseFloat(payload.amount),
                name: payload.name,
                phone: payload.phone,
            });

            yield put(bookingsActions.create(res.data));
            yield put(bookingsCreateActions.success());

            resolve(res.data);
        } catch (err) {
            yield put(bookingsCreateActions.failure(err.response.data.errors));
            reject(err);
        }
    },
};

/**
 * Saga Watchers
 */
export const bookingsCreateWatchers = function* () {
    yield takeLatest(bookingsCreateActionTypes.REQUEST, sagas.request);
};
