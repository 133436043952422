import { takeLatest, put } from 'redux-saga/effects';
import actionCreator from 'utils/actionCreator';
import axios from 'utils/axios';
import { bookingsActions } from './bookings';

/**
 * Action Types
 */
export const bookingsReadActionTypes = {
    REQUEST: '@app/bookings/read/REQUEST',
    FAILURE: '@app/bookings/read/FAILURE',
    SUCCESS: '@app/bookings/read/SUCCESS',
}

/**
 * Redux Actions
 */
export const bookingsReadActions = {
    request: actionCreator(bookingsReadActionTypes.REQUEST),
    success: actionCreator(bookingsReadActionTypes.SUCCESS),
    failure: actionCreator(bookingsReadActionTypes.FAILURE)
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }) {
        try {
            const res = yield axios.get('/bookings', { params: payload });

            yield put(bookingsActions.read({ bookings: res.data.results, total: res.data.total }));
            yield put(bookingsReadActions.success());

            resolve(res.data);
        } catch (err) {
            yield put(bookingsReadActions.failure(err.response.data.errors));
            reject(err);
        }
    }
}

/**
 * Saga Watchers
 */
export const bookingsReadWatchers = function* () {
    yield takeLatest(bookingsReadActionTypes.REQUEST, sagas.request)
}
