import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ChevronDown from 'static/svg/ChevronDown';

const TableAccordion = ({ title, items }) => {
    const [showItems, setShowItems] = useState(false);

    return <div className="table-accordion">
        <p className={`table-accordion__title ${showItems ? 'table-accordion__title--active' : ''}`} onClick={() => setShowItems(!showItems)}>
            <span>
                {title}
            </span>
            <ChevronDown />
        </p>
        {
            showItems &&
            <ul className="table-accordion__list">
                {items.map(item => (
                    <li className="table-accordion__item" key={item + Math.random()}>{item}</li>
                ))}
            </ul>
        }
    </div>
};

TableAccordion.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default TableAccordion;