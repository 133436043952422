import { takeLatest, put } from "redux-saga/effects";
import actionCreator from "utils/actionCreator";
import { authActions } from "./auth";

/**
 * Action Types
 */
export const authInitActionTypes = {
    REQUEST: "@app/auth/init/REQUEST",
    FAILURE: "@app/auth/init/FAILURE",
    SUCCESS: "@app/auth/init/SUCCESS",
};

/**
 * Redux Actions
 */
export const authInitActions = {
    request: actionCreator(authInitActionTypes.REQUEST),
    success: actionCreator(authInitActionTypes.SUCCESS),
    failure: actionCreator(authInitActionTypes.FAILURE),
};

/**
 * Saga Functions
 */
export const sagas = {
    *request() {
        try {
            // Clean up stale state
            localStorage.removeItem("adm:token");

            const token = yield localStorage.getItem("adm:token:v2");

            if (token) {
                yield put(authActions.register({ token }));
            }
        } catch (err) {
            yield put(authInitActions.failure(err.response.data.errors));
        }
    },
};

/**
 * Saga Watchers
 */
export const authInitWatchers = function* () {
    yield takeLatest(authInitActionTypes.REQUEST, sagas.request);
};
