import { takeLatest, put } from 'redux-saga/effects';

import axios from 'utils/axios';
import actionCreator from 'utils/actionCreator';

import { barbersActions } from './barbers';

/**
 * Action Types
 */
export const barbersUpdateActionTypes = {
    REQUEST: '@app/barbers/UPDATE/REQUEST',
    FAILURE: '@app/barbers/UPDATE/FAILURE',
    SUCCESS: '@app/barbers/UPDATE/SUCCESS',
}

/**
 * Redux Actions
 */
export const barbersUpdateActions = {
    request: actionCreator(barbersUpdateActionTypes.REQUEST),
    success: actionCreator(barbersUpdateActionTypes.SUCCESS),
    failure: actionCreator(barbersUpdateActionTypes.FAILURE)
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }) {
        try {
            const barberId = payload.barberId;
            delete payload.barberId

            const res = yield axios.patch(`/barbers/${barberId}`, payload);
            yield put(barbersActions.update(res.data));

            yield put(barbersUpdateActions.success());

            resolve();
        } catch (err) {
            yield put(barbersUpdateActions.failure(err.response.data.errors));
            reject();
        }
    }
}

/**
 * Saga Watchers
 */
export const barbersUpdateWatchers = function* () {
    yield takeLatest(barbersUpdateActionTypes.REQUEST, sagas.request)
}
