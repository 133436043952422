import React, { forwardRef } from 'react';

import Input from 'components/atoms/input/Input';
import Error from 'components/atoms/error/Error';

const ServicesInput = forwardRef(({ services, errors }, ref) => {
    return (
        <div className="services-input">
            {
                services.map((service, index) => {
                    return (
                        <div key={`service-input-${service.id}`} >
                            <div className="services-input__item">
                                <Input
                                    type="hidden"
                                    name={`services[${index}].id`}
                                    ref={ref}
                                    defaultValue={service.id}
                                />
                                <Input
                                    name={`services[${index}].title`}
                                    placeholder="Titulli"
                                    ref={ref({ required: 'Nevojitet' })}
                                    defaultValue={service.title}
                                />
                                <Input
                                    type="number"
                                    name={`services[${index}].price`}
                                    placeholder="Qmimi"
                                    ref={ref({ required: 'Nevojitet' })}
                                    defaultValue={service.price}
                                />$
                            </div>

                            {
                                errors?.services && errors.services[index] &&
                                <Error message="Plotesoji te gjitha fushat" />
                            }
                        </div>
                    )
                })
            }
        </div>
    );
});


export default ServicesInput;