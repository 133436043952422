import React from 'react';

import Heading from 'components/atoms/heading/Heading';

const ContentLayout = ({ title, actions = [], children }) => {
    return <main className="content-layout">
        <div className="content-layout__actions">
            <Heading type="secondary">{title}</Heading>
            {actions}
        </div>
        {children}
    </main>
};

export default ContentLayout;