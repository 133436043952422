import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

// Import reducers
import { rootReducer } from "./reducers";

import sagas from "sagas";
import { authInitActions } from "sagas/auth/authInit";
import { barbersReadActions } from "sagas/barbers/barbersRead";

// Create saga middleware
const sagaMiddleware = createSagaMiddleware();

// createStore
export default function configureStore(initialState = {}) {
    const middlewares = [sagaMiddleware];

    const enhancers = [applyMiddleware(...middlewares)];

    // If Redux DevTools Extension is installed use it, otherwise use Redux compose
    const composeEnhancers =
        process.env.NODE_ENV !== "production" &&
        typeof window === "object" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  shouldHotReload: false,
              })
            : compose;

    const store = createStore(rootReducer, initialState, composeEnhancers(...enhancers));

    // Initiate sagas
    sagaMiddleware.run(sagas);

    // Run auth and barbers init
    store.dispatch(authInitActions.request());
    store.dispatch(barbersReadActions.request());

    return store;
}
