import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import queryString from "query-string";

import { bookingsReadActions, bookingsReadActionTypes } from "sagas/bookings/bookingsRead";
import { bookingsDeleteActions } from "sagas/bookings/bookingsDelete";
import { bookingsUpdateActions } from "sagas/bookings/bookingsUpdate";
import useLoaderSelector from "hooks/useLoaderSelector";
import { selectBookings, selectBarbers } from "sagas/selectors";

import Button from "components/atoms/button/Button";
import Total from "components/atoms/total/Total";
import TableAccordion from "components/atoms/tableAccordion/TableAccordion";
import TableInput from "components/atoms/tableInput/TableInput";
import DateRange from "components/atoms/dateRange/DateRange";
import BarbersDropdown from "components/atoms/barbersDropdown/BarbersDropdown";
import Pagination from "components/molecules/pagination/Pagination";
import Table from "components/molecules/table/Table";
import BookingSidebar from "components/organisms/bookingSidebar/BookingSidebar";
import ContentLayout from "components/templates/contentLayout/ContentLayout";
import formatNumber from "utils/formatNumber";

import dispatchWithPromise from "utils/dispatchWithPromise";
import history from "utils/history";

const DAYS = ["E Diele", "E Hënë", "E Martë", "E Merkurë", "E Enjte", "E Premte", "E Shtunë"];
const MONTHS = [
    "Janar",
    "Shkurt",
    "Mars",
    "Prill",
    "Maj",
    "Qershor",
    "Korrik",
    "Gusht",
    "Shtator",
    "Tetor",
    "Nentor",
    "Dhjetor",
];

const Bookings = () => {
    const dispatch = useDispatch();
    const params = queryString.parse(history.location.search);
    const [dates, setDates] = useState({
        startDate: moment().tz("Europe/Belgrade").format("YYYY-MM-DD"),
        endDate: moment().tz("Europe/Belgrade").add(7, "days").format("YYYY-MM-DD"),
    });
    const [blockBooking, setBlockBooking] = useState(false);
    const [loading] = useLoaderSelector(bookingsReadActionTypes.REQUEST);
    const { bookings, total } = useSelector(selectBookings);
    const { barbers } = useSelector(selectBarbers);
    const [activeBarber, setActiveBarber] = useState(barbers[1]);
    const limit = 30;
    let totalEarnings = 0;
    let totalEmployeeCut = 0;

    useEffect(
        () =>
            dispatchWithPromise(dispatch, bookingsReadActions.request, {
                startDate: dates.startDate,
                endDate: dates.endDate,
                page: parseInt(params.page) || 1,
                barberId: activeBarber.id,
            }),
        [dispatch, params.page, dates, activeBarber],
    );

    const generateFormattedDate = date => {
        return `${moment(date).tz("Europe/Belgrade").format("D")} ${
            MONTHS[moment(date).month()]
        } ${moment(date).tz("Europe/Belgrade").format("yyyy")}, ${DAYS[moment(date).day()]}`;
    };

    const data = bookings?.map(booking => {
        if (!booking) return undefined;

        totalEarnings += parseFloat(booking.capturedAmount) || 0;
        totalEmployeeCut += parseFloat(booking.employeeCut) || 0;
        const time = moment(booking.date).tz("Europe/Belgrade").format("HH:mm");

        const capturedAmountElement = (
            <div>
                <TableInput
                    defaultValue={
                        !!booking.capturedAmount ? formatNumber(booking.capturedAmount) : 0
                    }
                    onChange={e => {
                        e.preventDefault();
                        dispatchWithPromise(dispatch, bookingsUpdateActions.request, {
                            bookingId: booking.id,
                            employeeCut: booking.employeeCut || 0,
                            capturedAmount: parseFloat(e.target.value) || 0,
                        });
                    }}
                />
            </div>
        );

        const employeeCutElement = (
            <div>
                <TableInput
                    defaultValue={!!booking.employeeCut ? formatNumber(booking.employeeCut) : 0}
                    onChange={e => {
                        e.preventDefault();
                        dispatchWithPromise(dispatch, bookingsUpdateActions.request, {
                            bookingId: booking.id,
                            capturedAmount: booking.capturedAmount || 0,
                            employeeCut: parseFloat(e.target.value) || 0,
                        });
                    }}
                />
            </div>
        );

        return {
            id: booking.id,
            klienti: `${booking.name} ${booking.blocked ? "(B)" : ""}`,
            numri: `${booking.phone}`,
            data: generateFormattedDate(booking.date),
            ora: time,
            serviset: (
                <TableAccordion
                    title="Serviset"
                    items={booking.bookingServices.map(
                        bs => `${bs.service.title} - ${bs.service.price}$`,
                    )}
                />
            ),
            totali: `${formatNumber(booking.totalAmount)}$`,
            totaliAktual: capturedAmountElement,
            prerja: employeeCutElement,
        };
    });

    const onDeleteClick = bookingId => {
        if (window.confirm(`A je i sigurte qe deshiron me fshi terminin #${bookingId}`))
            dispatchWithPromise(dispatch, bookingsDeleteActions.request, bookingId);
    };

    const onBarberChange = e => {
        const selectedBarber = barbers.filter(b => b.id === parseInt(e.target.value))[0];
        // Set the page to 1 when barber changes
        params.page = 1;
        history.location.search = queryString.stringify(params);

        setActiveBarber(selectedBarber);
    };

    return (
        <ContentLayout
            title="Terminet"
            actions={[
                <DateRange
                    key="date-range-bookings"
                    onDateChange={(startDate, endDate) =>
                        setDates({
                            startDate: moment(startDate).format("YYYY-MM-DD"),
                            endDate: moment(endDate || startDate).format("YYYY-MM-DD"),
                        })
                    }
                />,
                <BarbersDropdown
                    barbers={barbers}
                    selectedBarber={activeBarber.id}
                    onChange={onBarberChange}
                    key="dropdown-bookings"
                />,
                <Total
                    key="total"
                    data={[
                        { label: "Total", total: formatNumber(totalEarnings) },
                        { label: "Prerja", total: formatNumber(totalEmployeeCut) },
                    ]}
                />,
            ]}
        >
            <div className="bookings">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    !!data.length && (
                        <>
                            <Button onClick={() => setBlockBooking(true)}>Blloko Termin</Button>
                            <Table data={data || []} onDeleteClick={onDeleteClick} />
                            <Pagination pageCount={Math.ceil(total / limit)} />
                            {blockBooking && (
                                <BookingSidebar onClose={() => setBlockBooking(false)} />
                            )}
                        </>
                    )
                )}
            </div>
        </ContentLayout>
    );
};

export default Bookings;
