export default function formatNumber(value) {
    const number = parseFloat(value);

    // Check if the number is an integer
    if (Number.isInteger(number)) {
        return number;
    }

    // Format the number to one decimal place if it has decimals
    return number.toFixed(1);
}
