import React, { forwardRef } from 'react';

const Select = forwardRef(({ name, options, selectedOption, onChange }, ref) => {
    const elemOptions = options?.map(({ value, label }, index) => <option key={`${value}-${index}`} value={value}>{label}</option>)

    return (
        <div className="select">
            <select className="select__input" name={name} ref={ref} defaultValue={selectedOption} onChange={onChange}>
                {elemOptions}
            </select>
        </div>
    )
});

export default Select;