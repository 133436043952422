import React, { forwardRef } from 'react';

import Input from 'components/atoms/input/Input';
import Textarea from 'components/atoms/textarea/Textarea';
import Select from 'components/atoms/select/Select';
import ServicesInput from 'components/molecules/servicesInput/ServicesInput';
import Error from 'components/atoms/error/Error';

const FormGroup = forwardRef(({ name, label, type = "text", options, selectedOption, errors, onChange, services }, ref) => {
    const input = type === 'services' ?
        <ServicesInput services={services} ref={ref} errors={errors} />
        : type === 'select'
            ? <Select name={name} options={options} selectedOption={selectedOption} ref={ref} onChange={onChange} />
            : type === 'textarea'
                ? <Textarea ref={ref} name={name} />
                : <Input ref={ref} type={type} name={name} onChange={onChange} />

    return (
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            {input}
            {
                errors && errors[name]?.message &&
                <Error message={errors[name]?.message} />
            }
        </div>
    )
});

export default FormGroup;