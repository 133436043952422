import React from "react";

const Dropdown = ({ onChange, selectedBarber, barbers }) => {
    const elemOptions = barbers?.map(({ id, name }) => (
        <option key={`${id}-${name}`} value={id}>{name}</option>
    ));

    return (
        <select key="select" className="dropdown" defaultValue={selectedBarber} onChange={onChange}>
            {elemOptions}
        </select>
    )
};

export default Dropdown;