import actionCreator from 'utils/actionCreator';

/**
 * Action Types
 */
export const actionTypes = {
    REMOVE: '@app/errors/REMOVE',
    RESET: '@app/errors/RESET'
};

/**
 * Init state
 */
export const initState = {};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

const errorsReducer = (state = initState, { type, payload }) => {
    /*
     * Reset state
     */
    if (type === actionTypes.RESET) {
        return initState;
    }

    /*
     * Remove single action
     */
    if (type === actionTypes.REMOVE) {
        const { [payload]: value, ...removedState } = state;
        return removedState;
    }

    const isSuccess = type.includes('SUCCESS');
    const isRequest = type.includes('REQUEST');
    const isFailure = type.includes('FAILURE');

    if (isFailure) {
        return { ...state, [type]: payload };
    }

    if (isRequest || isSuccess) {
        const { [type.replace(/SUCCESS|REQUEST/, 'FAILURE')]: value, ...restState } = state;

        return restState;
    }

    return state;
}
export default errorsReducer;

/**
 * Redux actions
 */
export const errorsActions = {
    remove: actionCreator(actionTypes.REMOVE),
    reset: actionCreator(actionTypes.RESET)
};
