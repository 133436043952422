import React from 'react';

import Button from 'components/atoms/button/Button';

const Product = ({ product, onClick }) => {
    return <div className="product">
        <div className="product__main">
            <div className="product__content">
                <figure className="product__figure">
                    <img src={product.imgUrl} alt="Product One" className="product__img" />
                </figure>
                <h2 className="product__title">{product.title}</h2>
            </div>

            <Button onClick={() => onClick(product)}>Ndrysho</Button>
        </div>
    </div>
};

export default Product;