import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Total = ({ data }) => {
    return <div className="total">
        {
            data.map(d => (
                <Fragment key={d.label}>
                    <p className="total__label">{d.label}</p>
                    <p className="total__value">{d.total}$</p>
                </Fragment>
            ))
        }
    </div>
};

Total.propTypes = {
    data: PropTypes.array.isRequired
}

export default Total;