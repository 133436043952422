import React from 'react';
import Button from 'components/atoms/button/Button';

const BarberCard = ({ barber, onEditClick }) => {
    return <div className="barber-card">
        <figure className="barber-card__figure">
            <img src={barber.imgUrl} alt="Barber" />
        </figure>
        <p className="barber-card__name">{barber.name}</p>
        <p className="barber-card__rank">{barber.rank}</p>
        <Button onClick={() => onEditClick(barber)}>Ndrysho</Button>
    </div>
};

export default BarberCard;