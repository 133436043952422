import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import moment from "moment";

import { ordersReadActions, ordersReadActionTypes } from "sagas/orders/ordersRead";
import { ordersDeleteActions } from "sagas/orders/ordersDelete";
import { selectOrders } from "sagas/selectors";

import TableAccordion from "components/atoms/tableAccordion/TableAccordion";
import Table from "components/molecules/table/Table";
import Pagination from "components/molecules/pagination/Pagination";
import ContentLayout from "components/templates/contentLayout/ContentLayout";

import useLoaderSelector from "hooks/useLoaderSelector";
import dispatchWithPromise from "utils/dispatchWithPromise";
import history from "utils/history";

const Orders = () => {
    const dispatch = useDispatch();
    const params = queryString.parse(history.location.search);
    const { orders, total } = useSelector(selectOrders);
    const [isLoading] = useLoaderSelector(ordersReadActionTypes.REQUEST);
    const limit = 30;

    useEffect(
        () =>
            dispatchWithPromise(dispatch, ordersReadActions.request, {
                page: parseInt(params.page) || 1,
            }),
        [dispatch, params.page],
    );

    const data = orders.map(order => {
        const date = moment(order.createdAt).format("MMMM Do YYYY");

        return {
            id: order.id,
            klienti: order.user.name,
            numri: order.user.phone,
            adresa: order.user.address,
            qyteti: order.user.city.charAt(0).toUpperCase() + order.user.city.slice(1),
            produktet: (
                <TableAccordion
                    title="Produktet"
                    items={order.orderProducts.map(
                        op => `${op.product.title} - ${op.price}$ (x${op.quantity})`,
                    )}
                />
            ),
            data: date,
            totali: `${order.totalPrice}$`,
        };
    });

    const onDeleteClick = orderId => {
        if (window.confirm(`A je i sigurte qe deshiron me e fshi porosine #${orderId}`))
            dispatchWithPromise(dispatch, ordersDeleteActions.request, orderId);
    };

    return (
        <ContentLayout title="Porosite">
            <div className="orders">
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        <Table data={data || []} onDeleteClick={onDeleteClick} />
                        <Pagination pageCount={Math.ceil(total / limit)} />
                    </>
                )}
            </div>
        </ContentLayout>
    );
};

export default Orders;
