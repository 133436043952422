import React from 'react';

const FadeInAnimation = ({element = "div", className, children}) => {
    const Element = element;

    return (
        <Element className={`fadeInAnimation ${className}`}>
            {children}
        </Element>
    )
};

export default FadeInAnimation;