import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { selectAuth } from 'sagas/selectors';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated } = useSelector(selectAuth);

    if (!isAuthenticated) {
        return <Redirect to="/login" />;
    }
    return <Route component={Component} {...rest} />;
};

export default ProtectedRoute;
