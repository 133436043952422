import { takeLatest, put } from 'redux-saga/effects';

import axios from 'utils/axios';
import actionCreator from 'utils/actionCreator';

import { barbersActions } from './barbers';

/**
 * Action Types
 */
export const barbersReadActionTypes = {
    REQUEST: '@app/barbers/READ/REQUEST',
    FAILURE: '@app/barbers/READ/FAILURE',
    SUCCESS: '@app/barbers/READ/SUCCESS',
}

/**
 * Redux Actions
 */
export const barbersReadActions = {
    request: actionCreator(barbersReadActionTypes.REQUEST),
    success: actionCreator(barbersReadActionTypes.SUCCESS),
    failure: actionCreator(barbersReadActionTypes.FAILURE)
};

/**
 * Saga Functions
 */
export const sagas = {
    *request() {
        try {
            const res = yield axios.get('/barbers');
            yield put(barbersActions.read(res.data));

            yield put(barbersReadActions.success());
        } catch (err) {
            yield put(barbersReadActions.failure(err.response.data.errors));
        }
    }
}

/**
 * Saga Watchers
 */
export const barbersReadWatchers = function* () {
    yield takeLatest(barbersReadActionTypes.REQUEST, sagas.request)
}
