import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { selectAuth } from 'sagas/selectors'
import { authLogoutActions } from 'sagas/auth/authLogout';

import CalendarSvg from 'static/svg/Calendar';
import GraphSvg from 'static/svg/BarGraph';
import BarbersSvg from 'static/svg/Users';
import ProductSvg from 'static/svg/ShoppingBasket';

import LogoSvg from 'static/svg/Logo';
import LogoutSvg from 'static/svg/Logout';

import FadeInAnimation from 'components/hoc/fadeInAnimation/FadeInAnimation';
import history from 'utils/history';
import dispatchWithPromise from 'utils/dispatchWithPromise';

const Nav = () => {
    const { isAuthenticated } = useSelector(selectAuth);
    const dispatch = useDispatch();

    const { location: { pathname } } = history;

    const onLogout = () => dispatchWithPromise(dispatch, authLogoutActions.request);

    return (
        <nav className="nav">
            <div className="nav__logo-box">
                <LogoSvg />
            </div>

            {
                isAuthenticated &&
                <>
                    <FadeInAnimation element="ul" className="nav__content">
                        <li className={`nav__item ${pathname === '/' ? 'nav__item--active' : ''}`}>
                            <Link to="/" className="nav__link">
                                <CalendarSvg />
                                <span>Terminet</span>
                            </Link>
                        </li>
                        <li className={`nav__item ${pathname === '/orders' ? 'nav__item--active' : ''}`}>
                            <Link to="/orders" className="nav__link">
                                <GraphSvg />
                                <span>Porosite</span>
                            </Link>
                        </li>
                        <li className={`nav__item ${pathname === '/barbers' ? 'nav__item--active' : ''}`}>
                            <Link to="/barbers" className="nav__link">
                                <BarbersSvg />
                                <span>Berberat</span>
                            </Link>
                        </li>
                        <li className={`nav__item ${pathname === '/products' ? 'nav__item--active' : ''}`}>
                            <Link to="/products" className="nav__link">
                                <ProductSvg />
                                <span>Produktet</span>
                            </Link>
                        </li>
                    </FadeInAnimation>

                    <button className="nav__logout" onClick={onLogout}>
                        <LogoutSvg />
                        <span>Logout</span>
                    </button>
                </>
            }
        </nav>
    );
};

export default Nav;
