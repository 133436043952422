import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { selectBookings, selectBarbers } from 'sagas/selectors';
import { bookingsAppointmentsActions } from 'sagas/bookings/bookingsAppointments'
import { bookingsCreateActions, bookingsCreateActionTypes } from 'sagas/bookings/bookingsCreate'

import dispatchWithPromise from 'utils/dispatchWithPromise';
import validateDate from 'utils/validateDate';

import Button from 'components/atoms/button/Button';
import Error from 'components/atoms/error/Error';
import Sidebar from 'components/templates/sidebar/Sidebar';
import FormGroup from "components/molecules/formGroup/FormGroup";
import useErrorLoader from 'hooks/useErrorSelector';
import useLoaderSelector from 'hooks/useLoaderSelector';

const BookingSidebar = ({ onClose }) => {
    const { availableAppointments } = useSelector(selectBookings);
    const [isError] = useErrorLoader(bookingsCreateActionTypes.FAILURE)
    const [isLoading] = useLoaderSelector(bookingsCreateActionTypes.REQUEST)
    const { barbers } = useSelector(selectBarbers);
    const [activeBarber, setActiveBarber] = useState(barbers[1]);

    const dispatch = useDispatch();

    const { register, handleSubmit, errors, getValues } = useForm({
        mode: 'onTouched'
    });

    const onBarberChange = (e) => {
        const selectedBarber = barbers.filter(b => b.id === parseInt(e.target.value))[0];
        setActiveBarber(selectedBarber);

        if (getValues().date.length)
            dispatchWithPromise(
                dispatch,
                bookingsAppointmentsActions.request,
                {
                    date: getValues().date,
                    barberId: selectedBarber.id
                }
            );
    }

    const onDateChange = (e) => {
        const valid = validateDate(e.currentTarget.value);
        if (typeof valid !== 'string')
            dispatchWithPromise(
                dispatch,
                bookingsAppointmentsActions.request,
                {
                    date: e.currentTarget.value,
                    barberId: activeBarber.id
                }
            );
    }

    const onSubmit = (values) =>
        dispatchWithPromise(dispatch, bookingsCreateActions.request, {
            barberId: values.barber,
            date: values.date,
            time: values.time,
            amount: values.amount,
            name: values.name,
            phone: values.phone
        }).then(() => onClose())


    return (
        <Sidebar title="Blloko termin" onClose={onClose}>
            <div className="booking-sidebar">
                <h5>Te dhenat e terminit</h5>
                <form className="booking-sidebar__form">
                    <FormGroup
                        ref={register}
                        name="barber"
                        type="select"
                        label="Berberi"
                        options={barbers.map(b => ({
                            value: b.id,
                            label: b.name
                        }))}
                        onChange={onBarberChange}
                        selectedOption={activeBarber.id}
                    />

                    <FormGroup
                        ref={register({
                            required: 'Data eshte e nevojshme',
                            validate: value => validateDate(value)
                        })}
                        name="date"
                        type="date"
                        label="Data"
                        onChange={onDateChange}
                        errors={errors}
                    />

                    <FormGroup
                        ref={register({ required: 'Koha eshte e nevojshme' })}
                        name="time"
                        type="select"
                        label="Koha"
                        options={
                            availableAppointments.map(appointment => ({
                                value: appointment,
                                label: appointment
                            }))
                        }
                        errors={errors}
                    />

                    <FormGroup
                        ref={register({ required: 'Shuma eshte e nevojshme' })}
                        name="amount"
                        type="number"
                        label="Shuma"
                        errors={errors}
                    />

                    <FormGroup
                        ref={register({ required: 'Emri eshte i nevojshem' })}
                        name="name"
                        type="text"
                        label="Emri i klientit"
                        errors={errors}
                    />

                    <FormGroup
                        ref={register({
                            required: 'Numri eshte i nevojshem',
                            minLength: { value: 9, message: 'Numri duhet te jete mes 9 dhe 13 karaktereve i gjate' },
                            maxLength: { value: 13, message: 'Numri duhet te jete mes 9 dhe 13 karaktereve i gjate' },
                        })}
                        name="phone"
                        type="text"
                        label="Numri i klientit"
                        errors={errors}
                    />

                    <Button btnType="submit" onClick={handleSubmit(onSubmit)}>{isLoading ? 'Loading...' : 'Submit'}</Button>
                    {isError && <Error size="medium" message="Termini nuk ka mundur te kompletohet" />}
                </form>
            </div>
        </Sidebar>
    );
};

BookingSidebar.propTypes = {
    onClose: PropTypes.func.isRequired
}

export default BookingSidebar;