import React from "react";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { selectAuth } from 'sagas/selectors'
import { authActions, authActionTypes } from 'sagas/auth/auth';

import Button from "components/atoms/button/Button";
import Error from 'components/atoms/error/Error';
import FormGroup from "components/molecules/formGroup/FormGroup";
import ContentLayout from 'components/templates/contentLayout/ContentLayout';

import dispatchWithPromise from 'utils/dispatchWithPromise';
import history from 'utils/history';
import useErrorLoader from 'hooks/useErrorSelector';

const Login = () => {
    const [isError, error] = useErrorLoader(authActionTypes.FAILURE);
    const { isAuthenticated } = useSelector(selectAuth);
    const dispatch = useDispatch();

    const { register, handleSubmit, errors } = useForm({
        mode: 'onTouched'
    });

    const onSubmit = (values) => {
        dispatchWithPromise(dispatch, authActions.request, {
            email: values.email,
            password: values.password
        }).then(() => history.push('/'));
    }

    if (isAuthenticated) history.push('/');

    return (
        <ContentLayout title="Kyqu">
            <div className="login">
                <div className="login__container">
                    <form>
                        <FormGroup ref={register({ required: 'Email is required' })} name="email" type="text" label="Email" errors={errors} />
                        <FormGroup ref={register({ required: 'Password is required' })} name="password" type="password" label="Password" errors={errors} />
                        <Button btnType="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
                        {isError && <Error message={error[0]?.message} />}
                    </form>
                </div>
            </div>
        </ContentLayout>
    );
};

export default Login;
