import { combineReducers } from 'redux';

import barbers from 'sagas/barbers/barbers';
import auth from 'sagas/auth/auth';
import bookings from 'sagas/bookings/bookings';
import products from 'sagas/products/products';
import orders from 'sagas/orders/orders';

import errors from 'sagas/base/errors';
import loaders from 'sagas/base/loaders';

export const rootReducer = combineReducers({
    barbers,
    auth,
    bookings,
    products,
    orders,
    errors,
    loaders
})