import { takeLatest, put, delay } from 'redux-saga/effects';
import actionCreator from 'utils/actionCreator';
import axios from 'utils/axios';
import { productsActions } from './products';

/**
 * Action Types
 */
export const productsUpdateActionTypes = {
    REQUEST: '@app/products/update/REQUEST',
    FAILURE: '@app/products/update/FAILURE',
    SUCCESS: '@app/products/update/SUCCESS',
}

/**
 * Redux Actions
 */
export const productsUpdateActions = {
    request: actionCreator(productsUpdateActionTypes.REQUEST),
    success: actionCreator(productsUpdateActionTypes.SUCCESS),
    failure: actionCreator(productsUpdateActionTypes.FAILURE)
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }) {
        yield delay(500);
        try {
            const productId = payload.productId;
            delete payload.productId;

            const res = yield axios.patch(`/products/${productId}`, {
                ...payload,
                price: parseInt(payload.price),
                stock: parseInt(payload.stock)
            });

            yield put(productsActions.update(res.data));
            yield put(productsUpdateActions.success());

            resolve(res.data);
        } catch (err) {
            yield put(productsUpdateActions.failure(err.response.data.errors));
            reject(err);
        }
    }
}

/**
 * Saga Watchers
 */
export const productsUpdateWatchers = function* () {
    yield takeLatest(productsUpdateActionTypes.REQUEST, sagas.request)
}
