import { takeLatest, put } from 'redux-saga/effects';
import actionCreator from 'utils/actionCreator';
import axios from 'utils/axios';
import { ordersActions } from './orders';

/**
 * Action Types
 */
export const ordersDeleteActionTypes = {
    REQUEST: '@app/orders/delete/REQUEST',
    FAILURE: '@app/orders/delete/FAILURE',
    SUCCESS: '@app/orders/delete/SUCCESS',
}

/**
 * Redux Actions
 */
export const ordersDeleteActions = {
    request: actionCreator(ordersDeleteActionTypes.REQUEST),
    success: actionCreator(ordersDeleteActionTypes.SUCCESS),
    failure: actionCreator(ordersDeleteActionTypes.FAILURE)
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }) {
        try {
            const res = yield axios.delete(`/orders/${payload}`);

            yield put(ordersActions.delete(res.data.result));
            yield put(ordersDeleteActions.success());

            resolve(res.data);
        } catch (err) {
            yield put(ordersDeleteActions.failure(err.response.data.errors));
            reject(err);
        }
    }
}

/**
 * Saga Watchers
 */
export const ordersDeleteWatchers = function* () {
    yield takeLatest(ordersDeleteActionTypes.REQUEST, sagas.request)
}
