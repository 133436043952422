import React from 'react';

const ErrorIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.437" height="13.437" viewBox="0 0 13.437 13.437">
            <path id="Icon_material-error-outline" dataname="Icon material-error-outline" d="M9.047,11.734H10.39v1.344H9.047Zm0-5.375H10.39V10.39H9.047ZM9.712,3a6.719,6.719,0,1,0,6.725,6.719A6.715,6.715,0,0,0,9.712,3Zm.007,12.093a5.375,5.375,0,1,1,5.375-5.375A5.373,5.373,0,0,1,9.719,15.093Z" transform="translate(-3 -3)" fill="#c70000" />
        </svg>
    )
};

export default ErrorIcon;