import { takeLatest, put } from "redux-saga/effects";
import actionCreator from "utils/actionCreator";
import history from "utils/history";

import { authActions } from "./auth";

/**
 * Action Types
 */
export const authLogoutActionTypes = {
    REQUEST: "@app/auth/logout/REQUEST",
    FAILURE: "@app/auth/logout/FAILURE",
    SUCCESS: "@app/auth/logout/SUCCESS",
};

/**
 * Redux Actions
 */
export const authLogoutActions = {
    request: actionCreator(authLogoutActionTypes.REQUEST),
    success: actionCreator(authLogoutActionTypes.SUCCESS),
    failure: actionCreator(authLogoutActionTypes.FAILURE),
};

/**
 * Saga Functions
 */
export const sagas = {
    *request() {
        try {
            yield localStorage.removeItem("adm:token:v2");
            yield put(authActions.logout());
            yield put(authLogoutActions.success());
            yield history.push("/login");
        } catch ({ response }) {
            yield put(authLogoutActions.failure());
        }
    },
};

/**
 * Saga Watchers
 */
export const authLogoutWatchers = function* () {
    yield takeLatest(authLogoutActionTypes.REQUEST, sagas.request);
};
