import React from "react";
import Dismiss from 'components/atoms/dismiss/Dismiss'
import PropTypes from 'prop-types';

const Table = ({ data, onEditClick, onDeleteClick }) => {
    const columns = data.length ? Object.keys(data[0]).map(col => {
        const result = col.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }) : [];

    return (
        <table className="table">
            <thead>
                <tr>
                    {columns.map((col, index) => <th key={`${col}-${index}`}>{col}</th>)}
                    {
                        onEditClick &&
                        <th>&nbsp;</th>
                    }
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {data.map((d, index) => <tr key={`${d.id}-${index}`}>
                    {Object.values(d).map((val, index) => <td key={`${val}-${index}`}>{val}</td>)}
                    {
                        onEditClick &&
                        <td><button onClick={() => onEditClick(d)}>Ndrysho</button></td>
                    }
                    <td><Dismiss onClick={() => onDeleteClick(d.id)} size="small" /></td>
                </tr>)}
            </tbody>
        </table>
    );
};

Table.propTypes = {
    data: PropTypes.array.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onEditClick: PropTypes.func
}

export default Table;
