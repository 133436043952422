import actionCreator from 'utils/actionCreator';

/**
 * Action Types
 */
export const productsActionTypes = {
    READ: '@app/products/READ',
    UPDATE: '@app/products/UPDATE',
}

/**
 * Redux Actions
 */
export const productsActions = {
    read: actionCreator(productsActionTypes.READ),
    update: actionCreator(productsActionTypes.UPDATE),
};

/**
 * Init State
 */
export const initState = {
    products: [],
    total: 0
}

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
const productsReducer = (state = initState, action) => {
    switch (action.type) {
        case productsActionTypes.READ:
            return { ...state, ...action.payload }

        case productsActionTypes.UPDATE:
            const products = state.products.map(p => {
                if (p.id === action.payload.id) return action.payload;
                return p;
            });
            return { ...state, products }

        default:
            return state;
    }
};
export default productsReducer;