import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import { productsReadActions, productsReadActionTypes } from 'sagas/products/productsRead';
import { selectProducts } from 'sagas/selectors';

import Pagination from 'components/molecules/pagination/Pagination';
import Product from 'components/molecules/product/Product';
import ProductSidebar from 'components/organisms/productSidebar/ProductSidebar';
import ContentLayout from 'components/templates/contentLayout/ContentLayout';

import history from 'utils/history';
import dispatchWithPromise from 'utils/dispatchWithPromise';
import useLoaderSelector from 'hooks/useLoaderSelector';

const Products = () => {
    const params = queryString.parse(history.location.search);
    const [activeProduct, setActiveProduct] = useState(null);
    const { products, total } = useSelector(selectProducts);
    const [isLoading] = useLoaderSelector(productsReadActionTypes.REQUEST);
    const limit = 9;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatchWithPromise(
            dispatch,
            productsReadActions.request,
            { page: parseInt(params.page) || 1 }
        )
    }, [dispatch, params.page]);

    const onClick = (product) => {
        setActiveProduct(product);
    }

    return (
        <ContentLayout title="Produktet">
            <div className="products">
                {
                    isLoading
                        ? 'Loading...'
                        : <>
                            <div className="products__items">
                                {products.map(product => <Product key={product.id} product={product} onClick={onClick} />)}
                            </div>
                            <Pagination pageCount={Math.ceil(total / limit)} />
                        </>
                }
            </div>
            { activeProduct && <ProductSidebar type="edit" product={activeProduct} onClose={() => setActiveProduct(null)} />}
        </ContentLayout>
    )
};

export default Products;