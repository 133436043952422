import { takeLatest, put, delay } from "redux-saga/effects";
import actionCreator from "utils/actionCreator";
import axios from "utils/axios";
import { bookingsActions } from "./bookings";

/**
 * Action Types
 */
export const bookingsUpdateActionTypes = {
    REQUEST: "@app/bookings/update/REQUEST",
    FAILURE: "@app/bookings/update/FAILURE",
    SUCCESS: "@app/bookings/update/SUCCESS",
};

/**
 * Redux Actions
 */
export const bookingsUpdateActions = {
    request: actionCreator(bookingsUpdateActionTypes.REQUEST),
    success: actionCreator(bookingsUpdateActionTypes.SUCCESS),
    failure: actionCreator(bookingsUpdateActionTypes.FAILURE),
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }) {
        yield delay(500);
        try {
            const res = yield axios.patch(`/bookings/${payload.bookingId}`, {
                capturedAmount: payload.capturedAmount,
                employeeCut: payload.employeeCut,
            });

            yield put(bookingsActions.update(res.data));
            yield put(bookingsUpdateActions.success());

            resolve(res.data);
        } catch (err) {
            yield put(bookingsUpdateActions.failure(err.response?.data?.errors));
            reject(err);
        }
    },
};

/**
 * Saga Watchers
 */
export const bookingsUpdateWatchers = function* () {
    yield takeLatest(bookingsUpdateActionTypes.REQUEST, sagas.request);
};
