import actionCreator from 'utils/actionCreator';

/**
 * Action Types
 */
export const ordersActionTypes = {
    READ: '@app/orders/READ',
    DELETE: '@app/orders/DELETE',
}

/**
 * Redux Actions
 */
export const ordersActions = {
    read: actionCreator(ordersActionTypes.READ),
    delete: actionCreator(ordersActionTypes.DELETE),
};

/**
 * Init State
 */
export const initState = {
    orders: [],
    total: 0
}

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
const ordersReducer = (state = initState, action) => {
    switch (action.type) {
        case ordersActionTypes.READ:
            return { ...state, ...action.payload }

        case ordersActionTypes.DELETE:
            return { ...state, orders: [...state.orders.filter(o => o.id !== parseInt(action.payload))], total: state.total - 1 }

        default:
            return state;
    }
};
export default ordersReducer;