import React from 'react';

const PaginationLabelNext = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24.842" height="39.79" viewBox="0 0 24.842 39.79">
			<path id="Path_63" data-name="Path 63" d="M16981.34,394.793l14.943,14.945-14.943,14.945" transform="translate(-16976.391 -389.844)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="7" />
		</svg>
	)
}

export default PaginationLabelNext;