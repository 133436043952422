import React from 'react';

const PaginationLabelBack = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24.842" height="39.79" viewBox="0 0 24.842 39.79">
            <path id="Path_179" data-name="Path 179" d="M16996.285,394.793l-14.943,14.945,14.943,14.945" transform="translate(-16976.393 -389.844)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="7" />
        </svg>
    )
};

export default PaginationLabelBack;