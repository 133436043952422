import "styles/react-datepicker.css";
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

const DateRange = ({ onDateChange }) => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);

    const onChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        onDateChange(start, end)
    };

    return (
        <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            dateFormat="yyyy-mm-dd"
            selectsRange
            inline
        />
    );
};

export default DateRange;