import React from 'react';
import Dismiss from 'components/atoms/dismiss/Dismiss';

const Sidebar = ({ children, title, onClose }) => {
    return <div className="sidebar">
        <div className="sidebar__background" onClick={onClose}>&nbsp;</div>
        <div className="sidebar__content">
            <div className="sidebar__default">
                <h4 className="sidebar__title">{title}</h4>
                <Dismiss onClick={onClose} />
            </div>

            {children}
        </div>
    </div>
};

export default Sidebar;