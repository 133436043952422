import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import history from 'utils/history';

import PaginationLabelBack from 'static/svg/PaginationLabelBack';
import PaginationLabelNext from 'static/svg/PaginationLabelNext';

const Pagination = ({ pageCount }) => {
    const [activePage, setActivePage] = useState(1);
    const params = queryString.parse(history.location.search);

    const renderPageItems = () => {
        const items = []
        for (let i = 1; i <= pageCount; i += 1) {
            items.push(
                <li key={`page-${i}`} className={`pagination__page ${activePage === i ? 'pagination__page--active' : ''}`}>
                    <Link className="pagination__link" to={`?page=${i}`}>{i}</Link>
                </li>
            )
        }
        return items;
    }

    useEffect(() => {
        setActivePage(parseInt(params.page) || 1);
    }, [params]);

    return (
        <ul className="pagination">
            <li className={`pagination__icon ${activePage === 1 ? 'pagination__icon--inactive' : ''}`}>
                <Link to={activePage !== 1 ? `?page=${activePage - 1}` : `?page=${activePage}`}>
                    <PaginationLabelBack />
                </Link>
            </li>

            {renderPageItems()}

            <li className={`pagination__icon ${activePage === pageCount ? 'pagination__icon--inactive' : ''}`}>
                <Link to={activePage !== pageCount ? `?page=${activePage + 1}` : `?page=${activePage}`}>
                    <PaginationLabelNext />
                </Link>
            </li>
        </ul>
    )
};

export default Pagination;