import axios from "axios";
import { API_ENDPOINT } from "config/app";

// configure axios defaults
axios.defaults.baseURL = API_ENDPOINT;
axios.defaults.config = {
    headers: { "Content-Type": "multipart/form-data" },
};

export const setupAxios = () => {
    // request interceptor
    axios.interceptors.request.use(config => {
        const token = window.localStorage.getItem("adm:token:v2");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });
};

export default axios;
